var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"edit-restocking-product","title":"Editar producto","size":"lg","hide-footer":""}},[_c('validation-observer',{ref:"editProduct",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit()}}},[_c('div',{staticClass:"d-flex"},[_c('h5',[_vm._v("Editar producto")])]),_c('b-form-group',{attrs:{"label":"Inventario mínimo","label-for":"minimum-stock"}},[_c('validation-provider',{attrs:{"name":"Inventario deseado","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"threshold-inventory","state":errors.length > 0 ? false : valid ? true : null,"placeholder":"Inventario deseado","maxlength":"12"},model:{value:(_vm.selectedRestockingProduct.threshold_inventory),callback:function ($$v) {_vm.$set(_vm.selectedRestockingProduct, "threshold_inventory", $$v)},expression:"selectedRestockingProduct.threshold_inventory"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Inventario deseado","label-for":"threshold-inventory"}},[_c('validation-provider',{attrs:{"name":"Inventario mínimo","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"minimum-stock","state":errors.length > 0 ? false : valid ? true : null,"placeholder":"Inventario mínimo","name":"Inventario mínimo","maxlength":"12"},model:{value:(_vm.selectedRestockingProduct.minimum_stock),callback:function ($$v) {_vm.$set(_vm.selectedRestockingProduct, "minimum_stock", $$v)},expression:"selectedRestockingProduct.minimum_stock"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex justify-content-center flex-wrap"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.$bvModal.hide('edit-restocking-product')}}},[_vm._v(" Cancelar ")]),_c('b-button',{attrs:{"variant":"success","type":"submit","disabled":invalid}},[_vm._v(" Guardar ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }