<template>
  <b-modal
    id="automatic-restocking-info-modal"
    title="Información de pedido automático"
    hide-footer
    size="lg"
  >
    <ul v-if="selectedRestocking.id">
      <li
        v-for="item in selectedRestocking.restocking_store_products_attributes"
        class="mt-1 d-flex align-items-center"
      >
        <div class="d-flex align-items-center">
          <b-avatar
            size="32"
            :src="item.store_product_attributes.product_attributes.logo"
            :text="
              avatarText(item.store_product_attributes.product_attributes.name)
            "
            :variant="`light-primary`"
          />
          <div class="ml-50">
            <b-link class="font-weight-bold d-block text-wrap">
              {{ item.store_product_attributes.product_attributes.name }}
            </b-link>
            <small class="text-muted">{{
              item.store_product_attributes.product_attributes.variant
            }}</small>
            <div>
              <small class="text-muted">
                Inventario deseado:
                {{ item.threshold_inventory }}
              </small>
            </div>
            <div>
              <small class="text-muted">
                Inventario mínimo:
                {{ item.minimum_stock }}
              </small>
            </div>
          </div>
        </div>
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
          class="ml-auto"
          boundary="viewport"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item @click="handleSelectedRestockingProduct(item)">
            <feather-icon icon="Edit2Icon" />
            <span class="align-middle ml-50">Editar</span>
          </b-dropdown-item>
          <b-dropdown-item @click="handleRemoveRestokingProduct(item.id)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Borrar</span>
          </b-dropdown-item>
        </b-dropdown>
      </li>
    </ul>
  </b-modal>
</template>

<script>
import { avatarText } from "@core/utils/filter"

export default {
  components: {},
  props: {
    selectedRestocking: {
      type: Object,
      default: () => ({}),
    },
    handleSelectedRestockingProduct: {
      type: Function,
      default: () => {},
    },
    handleRemoveRestokingProduct: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>
