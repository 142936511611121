<template>
  <div class="cart p-1" tabindex="-1">
    <transition-group name="list" tag="div">
      <div
        v-for="product in restockingStoreProducts"
        :key="product.store_product_id"
        class="mb-50 p-1 product list-item"
        no-body
      >
        <div class="d-flex justify-content-center align-items-center">
          <b-img :src="product.logo" width="70" rounded />
        </div>
        <div class="px-1 d-flex flex-column">
          <div class="product-name mb-0 text-white font-weight-bold">
            {{ product.name }}
          </div>
          <div class="font-weight-bolder">
            {{ product.variant }}
          </div>
        </div>
        <product-modal :product="product" />
      </div>
    </transition-group>
    <div v-if="productsCount > 0" class="product-count text-center text-muted">
      <div class="mt-2 mb-1 h4">
        {{ productsCount }} producto<span v-if="productsCount > 1">s</span>
      </div>
      <b-button
        tabindex="-1"
        class="text-muted"
        variant="outline-secondary"
        size="sm"
        @click="clearRestockingStoreProducts"
      >
        <i class="fas fa-trash mr-1" /> Borrar
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import ProductModal from "../pos/ProductModal.vue";

export default {
  components: {
    ProductModal,
  },
  data() {
    return {
      selectedProduct: null,
    };
  },
  computed: {
    ...mapGetters("automaticRestocking", [
      "restockingStoreProducts",
      "productsCount",
    ]),
  },
  methods: {
    ...mapMutations("automaticRestocking", ["clearRestockingStoreProducts"]),
  },
};
</script>

<style lang="scss" scoped>
.cart {
  background-color: #12182c;
  overflow-y: auto;
  .product {
    border-radius: 5px;
    overflow: hidden;
    display: grid;
    grid-template-columns: auto 1fr auto;
    background: #161d31;
    transition: transform 0.25s ease;
    &:hover {
      background: #1b243d;
    }
    .product-name {
      font-size: 18px;
    }
  }
  .product-count {
    margin-bottom: 30px !important;
  }
}
.list-item {
  display: block;
}
.list-enter-active,
.list-leave-active {
  transition: all 5s ease;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translatex(800px);
}
</style>
