<template>
  <div>
    <b-button class="mr-15 mb-1" variant="primary" @click="handleProducts">
      <span>Productos seleccionados</span>
      <feather-icon
        icon="ShoppingCartIcon"
        class="ml-50"
        size="15"
        :badge="productsCount"
      />
    </b-button>

    <automatic-restocking-review-products />

    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="horizontal"
      finish-button-text="Confirmar"
      next-button-text="Siguiente"
      back-button-text="Regresar"
      class="wizard-vertical mb-3"
      shape="square"
      step-size="sm"
      @on-complete="formSubmitted"
    >
      <!-- Productos -->
      <tab-content title="Productos" :before-change="validationForm">
        <b-row>
          <b-col cols="12" class="my-2"> </b-col>
          <b-col>
            <add-store-products />
          </b-col>
        </b-row>
      </tab-content>

      <!-- Proveedor -->
      <tab-content
        title="Selecciona proveedor"
        :before-change="validateProvider"
      >
        <validation-observer ref="providerRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Selecciona a tu proveedor</h5>
            </b-col>
          </b-row>

          <!-- Select supplier -->
          <b-row class="px-1">
            <b-form-group label-for="establishment" class="custom-form-group">
              <validation-provider
                name="Proveedor"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="selectedEstablishment"
                  :options="establishments"
                  :reduce="(val) => val.id"
                  label="name"
                  placeholder="Selecciona al proveedor que deseas"
                  @input="getEstablishmentStores($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>

          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 v-if="this.selectedEstablishment" class="mb-0">
                Selecciona la sucursal de tu proveedor
              </h5>
            </b-col>
          </b-row>

          <!-- Store -->
          <b-row v-if="this.selectedEstablishment" class="px-1">
            <b-form-group label-for="Store" class="custom-form-group">
              <validation-provider
                name="Sucursal"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="automatic_restocking.buy_from_store"
                  :options="stores"
                  :reduce="(val) => val.id"
                  label="name"
                  placeholder="Selecciona la sucursal de tu proveedor"
                  @input="getStoreName($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Tipo de pedido -->
      <tab-content title="Tipo de pedido" :before-change="validateRestock">
        <validation-observer ref="restockRules" tag="form">
          <b-row class="justify-content-center">
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Tipo de pedido</h5>
              <div class="custom-form-group">
                <validation-provider
                  name="Tipo de pedido"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <div class="btn-group-toggle d-flex" data-toggle="buttons">
                    <label
                      v-for="typeOption in automatic_restock_type_options"
                      :key="typeOption.type"
                      class="btn btn-primary rounded-1 col-6 mb-2 mt-2 btn-size"
                      :class="{
                        active:
                          automatic_restocking.automatic_restock_type ===
                          typeOption.type,
                      }"
                    >
                      <input
                        type="radio"
                        :value="typeOption.type"
                        v-model="automatic_restocking.automatic_restock_type"
                      />
                      <span
                        class="
                          d-flex
                          align-items-center
                          justify-content-center
                          h-100
                        "
                        >{{ typeOption.name }}</span
                      >
                    </label>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
          </b-row>

          <b-row
            v-if="automatic_restocking.automatic_restock_type === 'by_date'"
          >
            <small class="text-warning">
              El proveedor recibirá tu pedido un día antes de la fecha de
              entrega para los días seleccionados
            </small>
          </b-row>
          <b-row
            v-if="automatic_restocking.automatic_restock_type === 'by_stock'"
          >
            <small class="text-warning">
              El proveedor recibirá el pedido cuando tu inventario esté por
              debajo del nivel que tú indiques
            </small>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Fechas de entrega -->
      <tab-content title="Fechas de entrega" :before-change="validateDelivery">
        <validation-observer ref="accountRules" tag="form">
          <!-- Restock schedule -->
          <b-row class="justify-content-center">
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Tipo de entrega</h5>
              <validation-provider
                name="Tipo de entrega"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="custom-form-group">
                  <div class="btn-group-toggle d-flex" data-toggle="buttons">
                    <label
                      v-for="typeOption in restock_schedule_type_options"
                      :key="typeOption.type"
                      class="btn btn-primary rounded-1 col-6 mb-2 mt-2 btn-size"
                      :class="{ active: restock_schedule === typeOption.type }"
                    >
                      <input
                        type="radio"
                        :value="typeOption.type"
                        v-model="restock_schedule"
                      />
                      <span
                        class="
                          d-flex
                          align-items-center
                          justify-content-center
                          h-100
                        "
                        >{{ typeOption.name }}</span
                      >
                    </label>
                  </div>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Days of week for restocking: weekly -->
          <validation-provider
            name="Días de entrega"
            rules="required"
            v-slot="{ errors }"
            v-if="
              restock_schedule === 'weekly' || restock_schedule === 'monthly'
            "
          >
            <b-row v-if="restock_schedule === 'weekly'">
              <b-form-group
                label="Días de entrega (puedes seleccionar más de uno)"
                label-for="weeklyDaysRestockSchedule"
                class="custom-form-group"
              >
                <v-select
                  v-model="automatic_restocking.days_of_week_for_restocking"
                  :options="days_of_week_for_restocking_weekly_options"
                  :reduce="(val) => val.type"
                  label="name"
                  multiple
                  :clearable="true"
                  placeholder="Selecciona los días de la semana o mes"
                />
              </b-form-group>
            </b-row>

            <!-- Days of week for restocking: monthly -->
            <b-row v-if="restock_schedule === 'monthly'">
              <b-form-group
                label="Días de entrega (puedes seleccionar más de uno)"
                label-for="daysRestockSchedule"
                class="custom-form-group"
              >
                <v-select
                  v-model="automatic_restocking.days_of_week_for_restocking"
                  :options="days_of_week_for_restocking_monthly_options"
                  :reduce="(val) => val.type"
                  label="name"
                  multiple
                  :clearable="true"
                  placeholder="Selecciona los días de la semana o mes"
                />
              </b-form-group>
            </b-row>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <!-- Preferred delivery time -->
          <validation-provider
            name="Horario de entrega"
            rules="required"
            v-slot="{ errors }"
            v-if="restock_schedule"
          >
            <b-row v-if="restock_schedule">
              <b-form-group
                label="¿Cuál es el mejor horario de entrega"
                label-for="preferredDeliveryTime"
                class="custom-form-group"
              >
                <v-select
                  v-model="automatic_restocking.preferred_delivery_time"
                  :options="timeArray"
                  :reduce="(val) => val.type"
                  label="name"
                  clearable
                  placeholder="Selecciona el mejor horario para ti"
                />
              </b-form-group>
            </b-row>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </validation-observer>
      </tab-content>

      <!-- Resumen de información -->
      <tab-content title="Confirmar">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Resumen de pedido automático</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="4" class="my-2">
            <p>
              <small class="mb-1 mt-1"> Proveedor: </small>
              <small class="text-warning">
                {{ this.establishment_name }} - {{ this.store_name }}
              </small>
            </p>

            <p>
              <small class="mb-1 mt-1"> Tipo de pedido: </small>
              <small class="text-warning">
                {{ restockType }}
              </small>
            </p>

            <p>
              <small class="mb-1 mt-1"> Tipo de entrega: </small>
              <small class="text-warning">
                {{ scheduleName }}
              </small>
            </p>

            <p>
              <small class="mb-0 mt-0"> Días de entrega: </small>
              <small class="text-warning">
                {{ dayName }}
              </small>
            </p>
          </b-col>
          <b-col cols="12" sm="8" class="my-2">
            <b-table
              :items="restockingStoreProducts"
              responsive
              :fields="tableColumnsDetails"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
              class="position-relative"
            >
              <!-- Product name  -->
              <template #cell(name)="product">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="32"
                      :src="product.item.logo"
                      :text="avatarText(product.item.name)"
                      :variant="`light-primary`"
                    />
                  </template>
                  <b-link class="font-weight-bold d-block text-nowrap">
                    {{ product.item.name }}
                  </b-link>
                  <small class="text-muted">{{ product.item.variant }}</small>
                </b-media>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import "@/@core/scss/vue/libs/vue-wizard.scss";
import BaseCropper from "@/@core/components/BaseCropper.vue";
import AddStoreProducts from "@/views/stores/AddStoreProductsToAutomaticRestockingSearchBar.vue";
import RestockingProductsCart from "@/views/stores/RestockingProductsCart.vue";
import AutomaticRestockingReviewProducts from "@/@core/components/automatic-restocking/AutomaticRestockingReviewProducts.vue";

import { required } from "@validations";

import Cleave from "vue-cleave-component";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,

    vSelect,
    Cleave,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BaseCropper,
    AddStoreProducts,
    avatarText,
    RestockingProductsCart,
    AutomaticRestockingReviewProducts,
  },

  setup() {
    return {
      // Filter
      avatarText,
    };
  },

  data() {
    return {
      selectedEstablishment: null,
      establishment_name: null,
      establishment: null,
      establishments: [],
      stores: [],
      store_name: null,
      restock_schedule: null,
      automatic_restock_type_options: [
        { type: "by_date", name: "Fecha" },
        { type: "by_stock", name: "Inventario" },
      ],
      restock_schedule_type_options: [
        { type: "weekly", name: "Semanal" },
        { type: "monthly", name: "Mensual" },
      ],
      days_of_week_for_restocking_weekly_options: [
        { type: "monday", name: "Lunes" },
        { type: "tuesday", name: "Martes" },
        { type: "wednesday", name: "Miercoles" },
        { type: "thursday", name: "Jueves" },
        { type: "friday", name: "Viernes" },
        { type: "saturday", name: "Sabado" },
        { type: "sunday", name: "Domingo" },
      ],
      days_of_week_for_restocking_monthly_options: Array.from(
        { length: 30 },
        (_, i) => ({
          type: (i + 1).toString(),
          name: `Dia ${i + 1} del mes`,
        })
      ),
      timeArray: [],

      automatic_restocking: {
        restock_schedule: null,
        automatic_restock_type: null,
        buy_from_store: null,
        days_of_week_for_restocking: [],
        preferred_delivery_time: null,
        restocking_store_products_attributes: [
          {
            store_product_id: null,
            threshold_inventory: null,
            minimum_stock: null,
          },
        ],
      },

      tableColumnsDetails: [
        {
          key: "name",
          label: "Producto",
        },
        {
          key: "threshold_inventory",
          label: "Inventario deseado",
        },
        {
          key: "minimum_stock",
          label: "Inventario mínimo",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("automaticRestocking", [
      "getStoreId",
      "restockingStoreProducts",
      "productsCount",
    ]),

    restockType() {
      const restockType = this.automatic_restocking.automatic_restock_type;
      const found = this.automatic_restock_type_options.find(
        (option) => option.type === restockType
      );
      return found ? found.name : "No encontrado";
    },

    scheduleName() {
      const scheduleType = this.restock_schedule;
      const found = this.restock_schedule_type_options.find(
        (option) => option.type === scheduleType
      );
      return found ? found.name : "No encontrado";
    },

    dayName() {
      const days = this.automatic_restocking.days_of_week_for_restocking;
      let found = days.map((day) =>
        this.days_of_week_for_restocking_weekly_options.find(
          (option) => option.type.toLowerCase() === day.toLowerCase()
        )
      );
      const names = found.map((item) => (item ? item.name : "No encontrado"));
      return names.join(", ");
    },
  },
  watch: {
    restock_schedule() {
      this.automatic_restocking.days_of_week_for_restocking = [];
    },
  },
  beforeMount() {
    this.fetchSuppliersForRestocking({
      meta: {
        pagination: {
          per_page: 1000,
        },
      },
    }).then((response) => {
      this.establishments = response.data;
    });
  },
  mounted() {
    this.generateTimeArray();
  },
  methods: {
    ...mapActions("establishments", ["fetchSuppliersForRestocking"]),
    ...mapActions("automaticRestocking", ["createAutomaticRestocking"]),
    ...mapMutations("automaticRestocking", ["clearRestockingStoreProducts"]),

    handleProducts() {
      if (this.productsCount > 0) {
        this.$bvModal.show("review-products");
      } else {
        this.$swal({
          text: "No hay productos en el carrito",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },

    generateTimeArray() {
      for (let i = 0; i < 24; i++) {
        const hour = i < 10 ? `0${i}` : `${i}`;
        const timeObject = {
          type: `${hour}:00:00`,
          name:
            i === 0
              ? "12:00 AM"
              : i < 12
              ? `${i}:00 AM`
              : i === 12
              ? "12:00 PM"
              : `${i - 12}:00 PM`,
        };
        this.timeArray.push(timeObject);
      }
    },

    getEstablishmentStores(event) {
      const selectedValue = event;
      this.establishment = this.establishments.find(
        (establishment) => establishment.id === selectedValue
      );

      this.establishment_name = this.establishment.name;
      this.stores = this.establishment.stores_attributes;
    },

    getStoreName(event) {
      const selectedValue = event;
      this.store_name = this.stores.find(
        (store) => store.id === selectedValue
      ).name;
    },

    formSubmitted() {
      this.automatic_restocking.restock_schedule = this.restock_schedule;
      this.automatic_restocking.restocking_store_products_attributes =
        this.restockingStoreProducts;

      this.clearRestockingStoreProducts();
      this.createAutomaticRestocking(this.automatic_restocking)
        .then((response) => {
          this.$swal({
            text: "Pedido automático creado con exito!",
            title: "Pedido automático creado con exito!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.$router.push({
            name: "store-automatic-restocking",
            params: { id: this.getStoreId },
          });
        })
        .catch((error) => {
          this.$swal({
            text: error.response.data.messages[0],
            title: "Pedido automático falló!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.$router.push({
            name: "store-automatic-restocking",
            params: { id: this.getStoreId },
          });
        });
    },
    validateDelivery() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: "Selecciona el tipo de entrega",
              },
            });
          }
        });
      });
    },
    validateRestock() {
      return new Promise((resolve, reject) => {
        this.$refs.restockRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: "Selecciona el tipo de pedido",
              },
            });
          }
        });
      });
    },
    validateProvider() {
      return new Promise((resolve, reject) => {
        this.$refs.providerRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: "Selecciona el proveedor y la sucursal",
              },
            });
          }
        });
      });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        if (this.productsCount > 0) {
          resolve(true);
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: "No hay productos seleccionados",
            },
          });
          reject();
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";

.promo-card {
  height: 120px;
}

.custom-form-group {
  width: 60%; /* Set the desired width for the input fields */
}

.vue-form-wizard {
  .wizard-header {
    display: none;
  }
  ul.wizard-nav-pills,
  .wizard-progress-with-circle {
    display: none !important;
  }
}

.btn-size {
  width: 100%;
  height: 60px;
  margin-right: 40px; /* Adjust the margin as needed */
  transition: background-color 0.7s ease; /* Smooth transition for color change */
}

.btn-size.active {
  background-color: #28a745 !important; /* Green color for the success variant */
}
</style>
