<template>
  <b-modal
    v-if="productsCount > 0"
    id="review-products"
    title="Resumen de productos seleccionados"
    class="modal-content"
    ok-variant="success"
    ok-title="Continuar"
    ok-only
    ok-click="$bvModal.hide('review-products')"
  >
    <div class="cart-modal">
      <restocking-products-cart />
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

import RestockingProductsCart from "@/views/stores/RestockingProductsCart.vue";

export default {
  components: {
    RestockingProductsCart,
  },
  computed: {
    ...mapGetters("automaticRestocking", ["productsCount"]),
  },
};
</script>
