<template>
  <b-container>
    <!-- Products search -->
    <b-card class="mb-2">
      <h3>Selecciona los productos para pedir automáticamente al proveedor</h3>
      <p>Buscar productos por nombre o código de barras</p>
      <b-input-group>
        <b-form-input
          id="sku"
          v-model="searchQuery"
          variant="outline-primary"
          placeholder="Nombre o Código de barras"
          @input="lookupStoreProducts"
        />
        <b-input-group-append v-if="!searchQuery">
          <b-button
            class="mr-1"
            variant="primary"
            @click="$bvModal.show('new-product-scanner')"
          >
            <i class="fas fa-qrcode" />
          </b-button>
          <b-modal id="new-product-scanner">
            <StreamBarcodeReader @decode="onDecode" />
          </b-modal>
        </b-input-group-append>
        <b-input-group-append v-else>
          <b-button
            variant="outline-warning"
            @click="clearSearchbarAndResetSearch()"
          >
            Borrar
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-card>
    <div v-if="searchQuery" class="checkout-items list-view">
      <!-- SEARCH RESULTS -->
      <b-card
        v-for="product in productsShown"
        :key="product.id"
        class="ecommerce-card mb-1 shadow border"
        no-body
      >
        <!-- Product Image -->
        <div>
          <b-link>
            <b-img
              :src="product.product_attributes.logo"
              :alt="`${product.product_attributes.name}-${product.product_attributes.id}`"
              width="120"
            />
          </b-link>
        </div>

        <!-- Product Details: Card Body -->
        <b-card-body>
          <div class="item-name">
            <h6 class="mb-0">
              <b-link class="text-body">
                {{ product.product_attributes.name }}
              </b-link>
            </h6>
            <div class="item-rating">
              {{ product.product_attributes.variant }}
            </div>
            <div>
              <b-badge
                v-for="cat in product.product_attributes.categories_names"
                :key="`cat-${cat}`"
              >
                {{ cat }}
              </b-badge>
            </div>
          </div>
        </b-card-body>

        <!-- Product Options/Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <p v-if="product.hasFreeShipping" class="card-text shipping">
                <b-badge pill variant="light-success"> Free Shipping </b-badge>
              </p>
            </div>
          </div>
          <b-button
            variant="success"
            class="mt-1 remove-wishlist"
            @click="
              showRestockingModal(
                product.id,
                product.product_attributes.logo,
                product.product_attributes.name,
                product.product_attributes.variant
              )
            "
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span>Agregar producto a pedido</span>
          </b-button>
        </div>
      </b-card>

      <!-- PAGINATION -->
      <b-pagination
        v-if="pagination.total_objects > pagination.per_page"
        v-model="pagination.page"
        :total-rows="pagination.total_objects"
        :per-page="pagination.per_page"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        @change="
          (value) => {
            handlePagination({ page: value, per_page: pagination.per_page });
          }
        "
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </div>
    <b-modal
      :visible="restockingStoreProductModal"
      id="restocking-store-product-modal"
      title="Detalles del producto"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
      :before-change="validationForm"
    >
      <b-badge
        pill
        :variant="'light-warning'"
        class="d-inline-block text-truncate"
        style="max-width: 100%"
      >
        {{ "¿Cuál es la cantidad óptima que deseas tener en inventario?" }}
      </b-badge>
      <validation-observer
        ref="restockingStoreProductInfo"
        tag="form"
        v-slot="{ invalid }"
      >
        <validation-provider
          #default="{ errors }"
          name="La cantidad que se desea tener es requerida"
          rules="required|numeric"
        >
          <b-input
            v-model="threshold_inventory"
            :state="errors.length > 0 ? false : null"
            placeholder="20"
            size="lg"
          >
          </b-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

        <div>
          <b-badge pill :variant="'light-warning'">
            {{ "¿Cuál es la cantidad mínima que deseas tener?" }}
          </b-badge>
        </div>

        <validation-provider
          #default="{ errors }"
          name="La cantidad mínima que se desea tener es requerida"
          rules="required|numeric"
        >
          <b-input
            v-model="minimum_stock"
            :state="errors.length > 0 ? false : null"
            placeholder="5"
            size="lg"
          >
          </b-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        
        <small class="text-danger" v-if="parseInt(minimum_stock) > parseInt(threshold_inventory)">
          El mínimo de stock no puede ser mayor al umbral de inventario
        </small>

        <div class="d-flex mt-2">
          <b-col cols="6">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              @click="hideRestockingModal()"
            >
              Cancelar
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-2"
              type="button"
              @click="pushScopedProducts()"
              :disabled="invalid || parseInt(minimum_stock) > parseInt(threshold_inventory)"
            >
              Continuar
            </b-button>
          </b-col>
        </div>
      </validation-observer>
    </b-modal>
  </b-container>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { debounce } from "lodash";
import { StreamBarcodeReader } from "vue-barcode-reader";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    StreamBarcodeReader,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    barcodeScanned: {
      type: String,
      default: () => null,
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      searchQuery: "",
      isCameraScannerActive: false,
      isCategoriesActive: false,
      restockingStoreProduct: [],
      restockingStoreProductModal: false,
      threshold_inventory: null,
      minimum_stock: null,
      store_product_id: null,
      logo: null,
      name: null,
      variant: null,
    };
  },
  computed: {
    ...mapGetters("stores", ["productsShown", "pagination"]),
    ...mapGetters("pos", ["settings"]),
  },
  watch: {
    barcodeScanned(val) {
      this.searchQuery = val;
      this.lookupStoreProducts(val);
    },
  },
  methods: {
    ...mapMutations("pos", ["toggleShowCategories"]),
    ...mapActions("stores", ["fetchStoreProducts"]),
    ...mapMutations("automaticRestocking", ["setRestockingStoreProducts"]),

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountStudentName.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    onDecode(result) {
      this.searchQuery = result;
      this.$bvModal.hide("new-product-scanner");
    },

    lookupStoreProducts: debounce(function searchQuery(query) {
      if (/^\d*$/.test(query) && query != null && query !== "") {
        const barcodeWithOutLastDigit = query.substring(0, query.length - 1);
        this.fetchStoreProducts({
          by_sku: Number(barcodeWithOutLastDigit),
          by_store: this.$route.params.id,
        }).then((response) => {
          if (response.data.length === 1) {
            if (navigator.vibrate) {
              navigator.vibrate(200);
            }
          }
        });
      } else if (query != null && query !== "") {
        this.fetchStoreProducts({
          by_name: query,
          by_store: this.$route.params.id,
        });
      } else if (query === null || query === "") {
        this.fetchStoreProducts({});
      }
    }, 250),

    handlePagination({ page, per_page }) {
      this.fetchStoreProducts({
        by_name: this.searchQuery,
        by_store: this.$route.params.id,
        meta: {
          pagination: {
            page,
            per_page,
          },
        },
      });
    },

    clearSearchbarAndResetSearch() {
      this.searchQuery = "";
    },

    pushScopedProducts() {
      this.restockingStoreProductModal = false;
      this.restockingStoreProduct.push({
        store_product_id: this.store_product_id,
        threshold_inventory: this.threshold_inventory,
        minimum_stock: this.minimum_stock,
        name: this.name,
        variant: this.variant,
        logo: this.logo,
      });

      this.clearSearchbarAndResetSearch();
      this.setRestockingStoreProducts(this.restockingStoreProduct);
      (this.threshold_inventory = null),
        (this.minimum_stock = null),
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Producto agregado",
              text: "Producto se ha agregado al pedido automático",
              icon: "EditIcon",
              variant: "success",
            },
          },
          {
            position: "top-right",
          }
        );
    },

    showRestockingModal(product, logo, name, variant) {
      this.store_product_id = product;
      this.logo = logo;
      this.name = name;
      this.variant = variant;
      this.restockingStoreProductModal = true;
    },

    hideRestockingModal() {
      this.restockingStoreProductModal = false;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
