<template>
  <b-table
    ref="refAutomaticRestockingListTable"
    hover
    :items="getAutomaticRestockings"
    responsive
    :fields="tableColumns"
    primary-key="id"
    show-empty
    empty-text="No matching records found"
    class="position-relative restocking-table"
  >
    <!-- Column: Status-->
    <template #cell(status)="data">
      <template>
        <b-badge
          v-if="data.item.active_status === 'active'"
          pill
          :variant="'light-success'"
        >
          Activo
        </b-badge>
        <b-badge v-else pill :variant="'light-danger'"> Cancelado </b-badge>
      </template>
    </template>

    <!-- Column: Automatic restocking type -->
    <template #cell(automatic_restock_type)="data">
      <template>
        <div>
          <b-badge pill :variant="'light-success'">
            {{
              data.item.automatic_restock_type | automaticRestockingTypeFilter
            }}
          </b-badge>
        </div>
      </template>
    </template>

    <!-- Column: Automatic restocking restock schedule -->
    <template #cell(restock_schedule)="data">
      <template>
        <div>
          <b-badge pill :variant="'light-info'">
            {{ data.item.restock_schedule | restockScheduleFilter }}
          </b-badge>
        </div>
      </template>
    </template>

    <!-- Column: Automatic restocking type -->
    <template #cell(days_of_week_for_restocking)="data">
      <template>
        <div class="gutter-sm">
          <!-- {{ data.item.days_of_week_for_restocking }} -->
          <b-badge v-for="day in data.item.days_of_week_for_restocking" pill>
            {{
              data.item.restock_schedule === "weekly" ? daysInSpanish[day] : day
            }}
          </b-badge>
        </div>
      </template>
    </template>

    <!-- Column: Details button -->
    <template #cell(products)="row">
      <b-button
        size="sm"
        :variant="'primary'"
        @click="row.toggleDetails"
        class="mr-2"
      >
        {{ row.detailsShowing ? "Ocultar" : "Ver " }} productos
      </b-button>
    </template>

    <template #row-details="data">
      <b-table
        :items="data.item.restocking_store_products_attributes"
        responsive
        :fields="tableColumnsDetails"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
      >
        <!-- Product name  -->
        <template #cell(name)="restocking_store_product">
          <b-media vertical-align="center" class="d-flex align-items-center">
            <template #aside>
              <b-avatar
                size="32"
                :src="
                  restocking_store_product.item.store_product_attributes
                    .product_attributes.logo
                "
                :text="
                  avatarText(
                    restocking_store_product.item.store_product_attributes
                      .product_attributes.name
                  )
                "
                :variant="`light-primary`"
              />
            </template>
            <b-link class="font-weight-bold d-block text-nowrap">
              {{
                restocking_store_product.item.store_product_attributes
                  .product_attributes.name
              }}
            </b-link>
            <small class="text-muted">{{
              restocking_store_product.item.store_product_attributes
                .product_attributes.variant
            }}</small>
          </b-media>
        </template>

        <template #cell(actions)="restocking_store_product">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="
                handleSelectedRestockingProduct(restocking_store_product.item)
              "
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="
                handleRemoveRestokingProduct(restocking_store_product.item.id)
              "
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Borrar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </template>

    <!-- Dropdown -->
    <template #cell(actions)="data">
      <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <b-dropdown-item
          :to="{
            name: 'add-store-automatic-restocking-products',
            params: { id: $route.params.id, order_id: data.item.id },
          }"
        >
          <feather-icon icon="Edit2Icon" />
          <span class="align-middle ml-50">Agregar productos</span>
        </b-dropdown-item>
        <b-dropdown-item @click="cancelRestocking(data.item.id)">
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Cancelar</span>
        </b-dropdown-item>
        <!-- registrar-pedido-automatico/:id/agregar-productos/:order_id 
        
           :to="{
            name: 'add-store-automatic-restocking',
            params: { id: $route.params.id },
          }"
        -->
      </b-dropdown>
    </template>
  </b-table>
</template>

<script>
import { mapGetters } from "vuex"

import { avatarText } from "@core/utils/filter"

export default {
  name: "AutomaticRestockingTable",
  data() {
    return {
      tableColumns: [
        {
          key: "status",
          label: "Status",
        },
        {
          key: "automatic_restock_type",
          label: "Tipo de pedido",
        },
        {
          key: "restock_schedule",
          label: "Frecuencia de entrega",
        },
        {
          key: "days_of_week_for_restocking",
          label: "Días de entrega",
        },
        {
          key: "products",
          label: "Productos",
        },
        {
          key: "actions",
          label: "Editar",
        },
      ],
      tableColumnsDetails: [
        {
          key: "name",
          label: "Producto",
        },
        {
          key: "threshold_inventory",
          label: "Inventario deseado",
        },
        {
          key: "minimum_stock",
          label: "Inventario mínimo",
        },
        {
          key: "actions",
          label: "Acciones",
        },
      ],
    }
  },
  computed: {
    ...mapGetters("automaticRestocking", ["getAutomaticRestockings"]),
  },
  props: {
    cancelRestocking: {
      type: Function,
      default: () => {},
    },
    daysInSpanish: {
      type: Object,
      default: () => {},
    },
    handleRemoveRestokingProduct: {
      type: Function,
      default: () => {},
    },
    handleSelectedRestockingProduct: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>
