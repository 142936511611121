<template>
  <b-modal id="edit-restocking-product" title="Editar producto" size="lg" hide-footer>
    <validation-observer ref="editProduct" v-slot="{ invalid }">
      <b-form class="mt-2" @submit.prevent="handleSubmit()">
        <div class="d-flex">
          <h5>Editar producto</h5>
        </div>

        <b-form-group label="Inventario mínimo" label-for="minimum-stock">
          <validation-provider
            #default="{ errors, valid }"
            name="Inventario deseado"
            rules="required|numeric"
          >
            <b-form-input
              id="threshold-inventory"
              v-model="selectedRestockingProduct.threshold_inventory"
              :state="errors.length > 0 ? false : valid ? true : null"
              placeholder="Inventario deseado"
              maxlength="12"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Inventario deseado"
          label-for="threshold-inventory"
        >
          <validation-provider
            #default="{ errors, valid }"
            name="Inventario mínimo"
            rules="required|numeric"
          >
            <b-form-input
              id="minimum-stock"
              v-model="selectedRestockingProduct.minimum_stock"
              :state="errors.length > 0 ? false : valid ? true : null"
              placeholder="Inventario mínimo"
              name="Inventario mínimo"
              maxlength="12"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <div class="d-flex justify-content-center flex-wrap">
          <b-button
            variant="outline-danger"
            class="mr-1"
            @click="$bvModal.hide('edit-restocking-product')"
          >
            Cancelar
          </b-button>
          <b-button
            variant="success"
            type="submit"
            :disabled="invalid"
          >
            Guardar
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate"

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  data() {
    return {
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  props: {
    handleEditProduct: {
      type: Function,
      required: true,
    },
    selectedRestockingProduct: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleSubmit() {
      this.$refs.editProduct.validate().then((success) => {
        if (success) {

          if (parseInt(this.selectedRestockingProduct.minimum_stock) > parseInt(this.selectedRestockingProduct.threshold_inventory)) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: "El inventario mínimo no puede ser mayor al inventario deseado",
              },
            })
            return
          }

          this.handleEditProduct(this.minimum_stock, this.threshold_inventory)
        }
      })
    },
  },
}
</script>
