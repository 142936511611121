<template>
  <b-list-group class="restocking-list">
    <b-list-group-item
      v-for="restocking in getAutomaticRestockings"
      :key="restocking.id"
      class="d-flex justify-content-between align-items-center"
      @click="handleSelectedRestocking(restocking)"
    >
      <b-media vertical-align="center">
        <div>
          <b-badge
            pill
            :variant="'light-success'"
            class="text-break whitespace-normal"
          >
            {{
              restocking.automatic_restock_type | automaticRestockingTypeFilter
            }}
          </b-badge>
          <b-badge
            v-if="restocking.active_status === 'active'"
            pill
            :variant="'light-success'"
          >
            Activo
          </b-badge>
          <b-badge v-else pill :variant="'light-danger'"> Cancelado </b-badge>
          <b-badge pill :variant="'light-info'">
            {{ restocking.restock_schedule | restockScheduleFilter }}
          </b-badge>
        </div>
        <div class="mt-1">
          <b-badge v-for="day in restocking.days_of_week_for_restocking" pill>
            {{
              restocking.restock_schedule === "weekly"
                ? daysInSpanish[day]
                : day
            }}
          </b-badge>
        </div>
      </b-media>
      <b-dropdown
        variant="link"
        no-caret
        :right="$store.state.appConfig.isRTL"
        boundary="viewport"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <b-dropdown-item
          :to="{
            name: 'add-store-automatic-restocking-products',
            params: { id: $route.params.id, order_id: restocking.id },
          }"
        >
          <feather-icon icon="Edit2Icon" />
          <span class="align-middle ml-50">Agregar productos</span>
        </b-dropdown-item>
        <b-dropdown-item @click.stop="cancelRestocking(restocking.id)">
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Cancelar</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { mapGetters } from "vuex"

import { avatarText } from "@core/utils/filter"

export default {
  name: "AutomaticRestockingList",
  data() {
    return {}
  },
  props: {
    cancelRestocking: {
      type: Function,
      default: () => {},
    },
    daysInSpanish: {
      type: Object,
      default: () => ({}),
    },
    handleSelectedRestocking: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("automaticRestocking", ["getAutomaticRestockings"]),
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>
